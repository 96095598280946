body {
  --color-background: #E5E5E5;
  --color-foreground: #14213D;
  --color-font: #00000;
  --color-badge: #00ff95;
  --color-font-nav: white;
}

body.dark {
  --color-background: #1f2023;
  --color-foreground: #000000;
  --color-font: #efefef;
  --color-badge: #0330ea;
  --color-font-nav: white;
}

.App {
  background-color: var(--color-background);
  /*background: rgb(0,0,0);*/
  /*background: radial-gradient(circle, rgba(0,0,0,1) 73%, rgba(9,124,140,1) 87%, rgba(242,241,252,1) 96%);*/
  /* WOOOOOOO way better than gray */
  color: var(--color-font);
  height: 100%;
  min-height: 120vh;
  margin: auto;
  font-size: medium;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


h2 {
  margin-top: 0px;
  text-decoration: underline;
  padding-top: 10px;
  color: var(--color-font)
}

.navbar {
  background-color: var(--color-foreground);
  border-bottom: 0px solid #ddd;
  align-items: center;
  padding: 10px 0px; /* Add padding for spacing */
}

.navbar ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

.navbar li {
  margin: 0;
  padding: 0;
}

.navbar a {
  text-decoration: none;
  color: var(--color-font-nav);
  font-weight: bold;
  height: 32px;
  display: flex;
  align-items: center;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar button {
  cursor: pointer;
}

.navbar button:hover {
  text-decoration: underline;
}

.navbar img {
  height: 25px;
  width: 25px;
}

.navbar button {
  background-color: var(--color-foreground);
  color: var(--color-font);
  width: 100px;
  height: 32px;
  border-radius: 8px;
}

.navbar .menuHamburgerButton {
  width: 50px;
  color: var(--color-font-nav);
}

.previewLink {
  color: var(--color-font);
  border-bottom: 1px solid black;
  padding: 5px 0px;
}

.previewLink a {
  color: var(--color-font);
}

.toolBadge {
  font-weight: bolder;
  border-radius: 10%;
  color: white;
  width: 250px;
  height: 250px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: larger;
  margin: 10px;
}

.blogArticle {
  font-weight: bold;
  font-size: large;
}

.blogArticle pre {
  background-color: #000000;
  padding: 5px 0px;
}

.blogArticle code {
  color: white;
}

pre {
  background: darkgray;
}


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--color-foreground);
  border: solid 1px white;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-font-nav);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 9px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  display: flex;
  width: 10px;
  height: 10px;
  top: 9px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 28px;
  top: 2px;
  border-color: var(--color-foreground);
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px var(--color-background);
  -moz-box-shadow: 0px 0px 3px 2px var(--color-background);
  box-shadow: 0px 0px 2px 3px var(--color-background);
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px var(--color-background);
  -moz-box-shadow: 0px 0px 5px 5px var(--color-background);
  box-shadow: 0px 0px 5px 5px var(--color-background);
}

